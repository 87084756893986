<template>
  <div class="w-100 min-width-200 mx-5 d-flex flex-column align-center">
    <div class="d-flex flex-row w-100 justify-between mt-5 mb-3">
      <span class="white--text font-30 app-medium-font ml-5">{{
        $t("notifications")
      }}</span>
      <div class="d-flex">
        <v-select
          :items="filterOptions"
          @change="onChangeOption"
          v-model="filterOption"
          outlined
          dense
          hide-details
          style="width: 120px"
          dark
        />
        <v-menu offset-y rounded="lg" left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" class="ml-2" dark>
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in dropMenus"
              v-on:change="onSelectedMenu(item)"
              :key="index"
              link
            >
              <v-list-item-title class="mr-3">
                <v-icon v-text="item.icon" class="mr-3" />
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <div class="pb-5 w-100">
      <notification-card
        v-for="notification in filteredNotifications"
        :key="notification._id"
        :notification="notification"
        :loadedData="loadedData"
        :loadMoreNotifications="loadMoreNotifications"
        class="mb-3"
      />
      <div
        v-if="notifications.length == 0 && !loading"
        class="mt-6 d-flex flex-column justify-center align-center"
      >
        <div class="mb-3">
          <v-img
            :src="require('@/assets/svg/empty-post.svg')"
            style="width: 60px; height: 50px"
            contain
          />
        </div>
        No notifications
      </div>
      <v-progress-circular
        indeterminate
        color="amber"
        v-if="loading"
        class="mt-6 w-100"
      />
    </div>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import NotificationCard from "./NotificationCard.vue";
export default {
  components: { NotificationCard },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      loadCompleted: -1,
      loadingMore: false,
      filteredNotifications: [],
      filterOption: "All",
      filterOptions: ["All", "Unread"],
      dropMenus: [
        { title: "Mark all as read", icon: "mdi-check" },
        { title: "Notification settings", icon: "mdi-cog-outline" },
      ],
    };
  },
  methods: {
    ...mapActions("notification", {
      fetchAllNotifications: "fetchAllNotifications",
      markAllAsReadNotifications: "markAllAsReadNotifications",
      loadMore: "loadMoreNotifications",
    }),
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    loadedData() {
      this.loadCompleted++;
    },
    loadMoreNotifications() {
      this.loadingMore = true;
      this.loadMore({ skip: this.notifications.length })
        .then(() => {
          setTimeout(() => {
            this.loadingMore = false;
          }, 2500);
        })
        .catch((error) => {
          this.loadingMore = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
    seeAll() {
      this.filtered = false;
    },
    seeUnread() {
      this.filtered = true;
    },
    onChangeOption(e) {
      if (e == "All") {
        this.filteredNotifications = [...this.notifications];
      } else if (e == "Unread") {
        this.filteredNotifications = this.notifications.filter((n) => !n.read);
      }
    },
    onSelectedMenu(item) {
      if (item.title == "Mark all as read") {
        this.markAllAsReadNotifications().catch((error) =>
          console.log(error.response.data.message)
        );
      } else if (item.title == "Notification settings") {
        this.$router.push({
          name: "settings",
          query: { category: "Notifications" },
        });
      }
    },
  },
  computed: {
    ...mapGetters("notification", {
      notifications: "getAllNotifications",
    }),
  },
  watch: {
    notifications(newValue) {
      this.filteredNotifications = [...newValue];
      this.filterOption = "All";
    },
  },
  mounted() {
    this.loading = true;
    this.loadCompleted = 0;
    this.fetchAllNotifications()
      .then(() => {
        this.loading = false;
        this.filteredNotifications = [...this.notifications];
      })
      .catch((error) => {
        this.loading = false;
        if (error.response == undefined) {
          console.log(error);
        } else {
          this.showErrorMessage(error.response.data.message);
        }
      });
  },
};
</script>
<style scoped></style>
