<template>
  <v-card style="width: 100%" @click="goToDetails">
    <v-card-subtitle class="d-flex flex-row">
      <div style="width: 60px; height: 60px;" v-if="notification.image">
        <v-img :src="notification.image" width="100%" height="100%" />
      </div>
      <div
        style="word-break: break-word; margin-left: 12px; font-weight: bold;"
      >
        <div v-html="notification.text"></div>
      </div>
      <div
        style="height: 12px; width: 12px; background: #0A5B8A; flex-shrink: 0; border-radius: 6px; margin-left: 10px; margin-top: 5px;"
        v-if="!notification.read"
      />
      <v-spacer />
      <div class="ml-3 d-flex flex-column align-end" style="min-width: 45px;">
        <span
          style="font-size:10px; color:darkgray;"
          v-text="getTimeAgo(notification.date)"
        />
        <v-menu offset-y rounded="lg" left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in dropMenus"
              v-on:change="onSelectedMenu(item)"
              :key="index"
              link
            >
              <v-list-item-title class="mr-3">
                <v-icon v-text="item.icon" class="mr-3" />
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-card-subtitle>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: {
    notification: Object,
  },
  data() {
    return {
      dropMenus: [
        { title: "Delete", icon: "mdi-delete" },
        { title: "Mark as read", icon: "mdi-email-mark-as-unread" },
      ],
    };
  },
  methods: {
    ...mapActions("notification", {
      markAsRead: "markAsRead",
      deleteNotification: "deleteNotification",
    }),
    goToDetails() {
      this.markAsRead({ _id: this.notification._id });
      if (this.notification.url.startsWith("https://")) {
        window.open(this.notification.url, "_blank");
      } else {
        this.$router.push(this.notification.url);
      }
    },
    getTimeAgo(oldTimeStr) {
      const oldTime = Date.parse(oldTimeStr);
      const time = new Date();
      const different = (time.getTime() - oldTime) / 1000;
      const month = (different / 3600 / 24 / 30) | 0;
      const days = (different / 3600 / 24) % 30 | 0;
      const hours = (different / 3600) % 24 | 0;
      const mins = (different / 60) % 60 | 0;
      if (month == 1) return `1 month`;
      if (month) return `${month} months`;
      if (days == 1) return `1 day`;
      if (days) return `${days} days`;
      if (hours == 1) return `1 hours`;
      if (hours) return `${hours} hours`;
      if (mins == 1) return `1 min`;
      if (mins) return `${mins} min`;
      return `less than 1 min`;
    },
    onSelectedMenu(item) {
      if (item.title == "Delete") {
        this.deleteNotification({ _id: this.notification._id }).catch(
          (error) => {
            console.log(error);
          }
        );
      } else if (item.title == "Mark as read") {
        this.markAsRead({ _id: this.notification._id });
      }
    },
  },
  mounted() {
    if (!this.notification.read) {
      this.dropMenus = [
        { title: "Delete", icon: "mdi-delete" },
        { title: "Mark as read", icon: "mdi-email-mark-as-unread" },
      ];
    } else {
      this.dropMenus = [{ title: "Delete", icon: "mdi-delete" }];
    }
  },
};
</script>
<style scoped></style>
